<template>
  <div class="container-fluid">
    <div class="bg"></div>
    <div class="row L1">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 text-center">
            <h1>Capture Counter <span>2.0.0</span> </h1>
            <p>拖入一张raw照片，读取相机的快门数</p>
            <div class="btnbox hidden-xs">
              <a class="btn" v-if="isWin" href="https://www.microsoft.com/store/productid/9NJD0BKZCG36?ocid=pdpshare" target="_blank">前往微软商店</a>
              <a class="btn" v-else href="https://apps.apple.com/cn/app/capture-counter-%E7%AE%80%E5%8D%95%E5%BF%AB%E9%80%9F%E5%9C%B0%E6%9F%A5%E7%9C%8B%E7%9B%B8%E6%9C%BA%E5%BF%AB%E9%97%A8%E6%95%B0/id6450496137?mt=12" target="_blank">前往AppStore</a>
            </div>
            <div class="btnbox visible-xs">
              <a class="btn" href="javascript:void(0);">请前往桌面端下载</a>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="screenshot">
              <img  draggable="false" class="img-responsive" src="@/assets/img/capturecounter/1.gif" alt="" srcset="" >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row L2">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="page-header">
              <h1>软件说明</h1>
            </div>
            <p>该工具支持查看索尼、尼康、富士、宾得相机的机械快门数，您可以用您的相机，拍摄一张RAW格式的照片，并拖入软件，软件即可提示您相机目前机械快门数是多少。</p>
            <p>如果您已将ARW文件转换成了DNG格式，那么同样可以读取快门数。所读取的快门数是拍摄当前这个文件时的快门数，可能和实际相机的快门数有差别。</p>
            <p>数据库最后更新于2023年6月21日，在此之前发售的索尼、尼康、富士、宾得品牌的相机均支持。</p>
            <p>本工具是完全离线的，您的文件将不会被上传到任何网站，确保隐私不会泄露。</p>
          </div>
          <div class="col-xs-12">
            <div class="page-header">
              <h1>技术支持</h1>
            </div>
            <p>Tech Support: mordom@foxmail.com</p>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>
<script>
export default {
  name: 'CaptureCounter',
  metaInfo: {
    title: 'Capture Counter - 快门数查看工具', // set a title
    meta: [{             // set meta
      name: 'keywords',
      content: '索尼,sony,索尼相机,快门,快门数,rawdigger,查询,查看,机械快门,eos,a7r2,a7r3,a7m3,a7r4,a7m4,a7r5,NEX,ILCE,SONY,阿尔法,α,无反,微单,尼康,富士,宾得,nikon,fujifilm,pentax'
    },
    {
      name: 'description',
      content: '通过一张照片快速查看索尼、尼康、富士、宾得相机的机械快门数'
    }],
    link: []
  },
  props: {
    
  },
  computed:{
    isWin(){
      return window.navigator.platform == 'Win32'
    },
  },
  data(){
    return {
      publicPath: process.env.BASE_URL
    }
  },
  mounted(){
    
  },
  methods:{
    download(link){
      var a = document.createElement('a');
      a.href = link;//下载文件地址
      a.click(); 
    },
  }

}
</script>
<style lang="less" scoped>
	.flex{
		display: flex;
		align-items: center;
		justify-content: flex-start;	
	}
  .container-fluid{
    width: 100%;
    padding: 0;
    margin: 0;
    .bg{
      width: 100%;
      height: 800px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      background-image: linear-gradient(25deg, #472a5d, #4b5480, #427ea4, #18a9ca);
    }
    .row{
      margin: 0;
      padding: 0;
    }
    .L1{
      color: #fff;
      padding-top: 100px;
      height: 1200px;
      h1{
        font-size: 64px;
        font-weight: 300;
        margin-top: 70px;
        span{
          font-size: 14px;
          border: 1px solid #fff;
          padding: 0 8px;
          border-radius: 5px;
          line-height: 1;
        }
        @media (max-width: 970px) { 
          margin: 100px 0 50px;
        }
      }
      p{
        font-weight: 300;
      }
      .btnbox{
        text-align: center;
        margin: 60px 0;
        .btn{
          font-size: 16px;
          line-height: 1;
          padding: 8px 20px 8px;
          border: 1px solid #fff;
          color: #fff;
          border-radius: 20px;
          margin: 10px 0;
          display: inline-block;
          margin-right: 10px;
          &:hover{
            color: #46bdbd;
            background-color: #fff;
          }
        }
        a{
          color: #fff;
        }
        &.visible-xs{
          .btn{
            border: none;
            box-shadow: none;
            &:hover{
              background: transparent;
              color: #fff;
            }
          }
        }
      }
      .screenshot{
        width: 100%;
        overflow: hidden;
        text-align: center;
        padding-bottom: 50px;
        img{
          border-radius: 10px;
          width: 80%;
          max-width: 500px;
          display: inline-block;
          box-shadow: 0 0 30px rgba(0,0,0,0.2);
        }
      }
    }
    .L2{
      .page-header{

      }
      h3{
        span{
          font-size: 16px;
          font-weight: normal;
          margin-left: 20px;
        }
      }
    }
    @media screen and (max-width:900px){
      .L1{
        height: 800px;
      }
      .L2{
        .panel{
          .panel-inbox{
            padding: 10px 20px;
          }
          h4{
            font-size: 28px;
          }
        }
      }
    }
  }
</style>